import React from "react"

const Rooms =() =>{

    return <>
    
        <h1>Roooms</h1>
    

        </>

}

export default Rooms
 